'use strict'

###* @ngdoc object
 # @name lpa
 # @description

###
angular
.module 'lpa', [
  'lpaDispatching'
  'lpaData'
  'lpaFastTracking'
  'lpaIncidentTracking'
  'uuid4'
  'lpaMapUtils'
  'ngMaterial'
  'angularMoment'
  'angular-humanize-duration'
]
.config ($mdThemingProvider) ->
  $mdThemingProvider.definePalette 'lpaPalette',
    '50' :'cadefd'
    '100':'407bd8'
    '200':'185ac3'
    '300':'104fb3'
    '400':'0b449e'
    '500':'00368b'
    '600':'042d6d'
    '700':'08234e'
    '800':'0b2246'
    '900':'091933'
    'A100': 'ff8a80'
    'A200': 'ff5252'
    'A400': 'ff1744'
    'A700': 'd50000'
    'contrastDefaultColor': 'light'
    'contrastDarkColors': ['50', '100','200', '300', '400', 'A100']
    'contrastLightColors': undefined

  $mdThemingProvider.theme 'default'
    .primaryPalette 'lpaPalette'
